@import 'Colours';

.authLandingContainer {  
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 30px 0;
    text-align: center;

  h1 {
    margin-bottom: 10px;
  }
  p {
    font-size: 1.2rem;
  }
  a{
    margin-top: 2px;
    font-size: 1.2rem;
  }
}

.oAuthBtn {
  margin-top: 20px;
  background: #FFFFFF !important;
  border: 2px solid;
  border-radius: 6px;
  padding: 5px 25px;
  height: 2.8em;
  color: black !important;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  box-shadow: none !important;

  &:first-of-type {
    margin-top: 20px;
  }

  p {
    font-weight: 500;
    margin-left: 10px;
  }

  &:hover {
    cursor: pointer;
    background-color: transparent !important;
    color: black !important;
    text-decoration: none !important;

    &.oAuthBtnXero {
      border-color: #00B7E2;
    }
    &.oAuthBtnShopify {
      border-color: #96BF48;
    }
  }

  &.oAuthBtnXero {
    border-color: #00B7E2;
  }
  &.oAuthBtnShopify {
    border-color: #96BF48;
  }
}

.authForm {
  padding: 50px 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &.forgotPasswordForm{
    .formTitle {
      font-size: 25px;
      margin-bottom: 0;
      font-weight: 400;
    }
    .subtitle {
      font-size: 0.9rem;
      opacity: 0.9;
    }
  }

  .formTitle {
    font-size: 25px;
    margin-bottom: 25px;
    font-weight: 400;

    &.loginFormTitle {
      margin-bottom: 5px;
    }
  }

  .verifyEmailText {
    font-size: 0.9rem;
    opacity: 0.9;
  }

  .subtitle {
    font-size: 20px;
  }

  .email {
    font-weight: 500;
  }

  .marginSeperator {
    margin-right: 0.25rem!important;
    margin-left: 0.25rem!important;
  }

  .xeroButton{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
  }

  .authButton {
    height: 2.6em;
  }

  .fullButton {
    width: 100%;
    margin: 2em 0 0 0;
  }

  .verifyButton {
    margin-bottom: 5px;
  }

  .authDivider {
    margin: 1.5em 0 1.5em 0;
  }

  .smallButton {
    height: 1.9em;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.75em;
    margin: 0.6em 0.6em 0.6em 0;
  }

  .topButton {
    margin-bottom: 1.5em;
  }

  .altLink{
    margin-top: 1em;
  }

  .formContainer {
    border-radius: 2px;
    padding-top: 5px;
    margin: 0 10px;
    width: 32em;
    display: flex;
    justify-content: center;
    border: 1px solid rgba(0, 0, 0, 0.116);
    background-color: #FAFAFA;
  }

  .fieldsContainer {
    margin: 20px 30px;
    width: 100%;
    
    @media screen and (max-width: 500px) {
      margin: 20px 10px;
    }
  }

  .disableBtn {
    opacity: 0.7;
    &:hover {
      background-color: $payment-source-blue;
    }
  }

  .textSuccess {
    color: $state-success;
  }
  .bgSuccess {
    background-color: $state-success;
  }
  .textDanger {
    color: $state-danger;
    margin: 0 !important
  }
  .flexWrap {
    flex-wrap: wrap !important;
  }
  .bgDanger {
    background-color: $state-danger;
  }
  
  .flexRow {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .flexRow.spaceBetween {
    justify-content: space-between;
  }

  .emailResendWaitTxt {
    line-height: 1rem;
    font-size: 90%;
  }

  .resendAlertText {
    margin-bottom: 0!important;
    margin-right: 0.25rem!important;
    margin-left: 0.25rem!important;
  }

  .googleRecaptchaDisclaimer {
    font-size: 11px;
    margin-bottom: 10px;
    margin-top: 7px;
  }
}

.linkBtn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 2.6em;
  min-width: 210px;
  font-weight: 500;
  font-size: 1em;
  font-family: inherit;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
  padding: 5px 25px;
  transition: background-color 0.1s, color 0.1s, border-color 0.1s;
  --btn-primary: #{$payment-source-blue};
  --btn-secondary: white;
  --btn-hover: #{$payment-source-dark-blue};

  .fastSpin {
    -webkit-animation: fa-spin 0.5s infinite linear;
    -moz-animation: fa-spin 0.5s infinite linear;
    animation: fa-spin 0.5s infinite linear;
  }

  &[disabled] {
    pointer-events: none;
  }

  &.disable {
    opacity: 0.7;
  }

  border: 0.18rem solid var(--btn-primary);


    background: var(--btn-primary);
    color: var(--btn-secondary) !important;

    &:hover {
      background-color: var(--btn-hover);
      border-color: var(--btn-hover);
      text-decoration: none !important;
    }
  

  &:focus {
    box-shadow: 0 0 0 0.2rem #27245b81;
  }
}