.modal {
  position: fixed;
  z-index: 99;
  top:0;
  left:0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.349);
  display: flex;
}

.modal_content {
  background-color: white;
  max-width: 40em;
  width: 100%;
  padding: 10px 20px 20px 20px;
  border-radius: 5px;
  margin: 20px
}

.close {
  float: right;
  background-color: transparent !important;
  color: gray !important;
  width: auto;
  padding: 3px;
  margin: 0;
  height: auto;
  border: none;
  box-shadow: none !important;
  border-radius: 2px;
  svg{
    font-size: 1.3em;
  }
}

.modalTxt {
  clear: both;
  h2{
    font-size: 1.25em;
    padding-bottom: 8px;
  }

  p{
    padding-bottom: 20px;
  }

  .buttonCont{
    margin-top: 5px;
    display: flex;
    column-gap: 20px;

    .uppercase{
      text-transform: uppercase;
    }

    button{
      height: 2.6em;
      padding: 5px 35px;
    }
  }
  
}