@import 'Colours';

.disabledField {
  background-color: #e9e9e9 !important;
  background-image: none !important;
  border: 1px solid #BCBCBC !important; 
}

.inputGroupText {
  margin-top: 5px;
  background-color: #f5f5f5;
}

.fixFieldAlignment {
  margin-top: 20px !important;
}

.formSelect.fixFieldAlignment {
  margin-top: 20px !important;
}

.multiChkboxFieldsetNoMargin {
  border: none;
  
  .formCheckbox {
    margin-top: 5px;
  }
}

.multiChkboxFieldset {
  border: none;
  margin-top: 20px;

  .formCheckbox {
    margin-top: 5px;
  }
}

.disabledCheckbox {

  label {
    opacity: 0.7;
  }
}

.formCheckbox {
  margin-top: 20px;

  input {
    border-radius: 3px !important;
  }
}

.chkLabelContainer {
  margin-top: 10px;
  margin-left: 10px;
}

.fileField {
  width: 100%;
  position: relative;
}

.fileContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  height: 3.1em;

  .fileInput {
    flex: 1;
    cursor: pointer;
  }

  button {
    border-radius:6px !important;
  }
}

.requiredDisclaimer {
  margin-bottom: 5px;
  opacity: 0.8;
  margin-right: 5px;
  font-size: 0.8rem;
}

.formFieldGroup {
  margin-top: 0px !important;

  input {
    font-size: 14px !important;
    margin-top: 2.5px !important;
  }

  .errorTxt {
    margin-top: 5px;
  }
}

.formField {
  margin-top: 20px;

  &.amountField>input {
    line-height: 2;
    font-size: 1.3rem;
    text-align: right;
  }

  select, input {
    margin-top: 5px;
    border-radius: 5px;
    border: 1px solid #BCBCBC;
    font-size: 15px;
  }

  .formSelect {
    height: 2.9em;
    margin-top: 5px;
    display: block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    -moz-padding-start: -moz-calc(.75rem - 3px);
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &:focus {
      border-color: #86b7fe;
      outline: 0;
      -webkit-box-shadow: 0 0 0 .25rem rgba(13,110,253,.25);
      box-shadow: 0 0 0 .25rem rgba(13,110,253,.25);
    }
  }

  label {
    font-size: 16px;
    font-weight: 400;
  }

  input.password {
    padding-right: 35px;
  }

  .optionalLbl {
    margin-left: 5px;
    opacity: 0.8;
    font-size: 90%;
  }

  .formatLbl {
    margin-bottom: 0;
    opacity: 0.8;
    font-size: 0.8rem;
  }

  .helpTxt,
  ul.helpTxt li {
    margin-bottom: 0;
    opacity: 0.9;
    font-size: 0.8rem;
    margin-top: 5px;
  }

  .helpTxt li,
  ul.helpTxt {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }

  .passwordFieldContainer{
    flex-direction: row;
  }

  .passwordShowToggle {
    float: right;
    margin-top: 8px;
    width: 3.5em;
    background-color: transparent;
    border: none;
    z-index: 99;
    cursor: pointer;
  }

  .errorTxt {
    display: block !important;
  }
}

.inputGroupButton {
  height: auto;
  margin-top: 5px;
  border-radius: 0.25rem;
}

.dateRangeButtonCont {
  display: flex;
  align-items: flex-end;
}

.dateRangeButton {
  font-size: 1rem;
  min-width: 0.1rem !important;
  height: 2.6em ;
  width: 0.1rem !important;
  border-radius: 5px !important;
  margin-bottom: 0px;
  box-shadow: none !important;
  margin-right: 5px;
}

@media only screen and (max-width: 1050px) {

  .dateRangeButton{
    margin-bottom: 10px !important;
  }
}

.optionalChkLbl {
  margin-right: 5px;
  opacity: 0.8;
}

.passwordLblDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

input.invalid {
  border-color: red;
}

.dateRangePicker{
  z-index: 9999999;
}

.authBackgroundLeavesWrapper {
  position: absolute;
  width:100%;
}

/*PASSWORD*/
.bothPasswordField {
  display: flex;
  flex-direction: column;
}

.passwordFieldContainer {
  display: flex;
  flex-direction: column;
}

/*PASSWORD HIDE EYE*/
.passwordField {
}

@media only screen and (max-width: 767px) {
  .formBackgroundContFull {
    padding-top: 6vh !important;
  }
}

.formInputsRow {
  display: flex;
  flex-direction: row;
  align-items: center;

  .formField:first-child {
    margin-right: 4rem;
  }

  @media only screen and (max-width: 500px) {
    flex-direction: column;
    align-items: start;

    .formField:first-child {
      margin-right: 0;
    }
  }
}
